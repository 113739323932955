body {
    margin: 0;
    font-family: -apple-system, 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.form-container-login {
    background : url('../../Assets/back.png') right top/auto 500px no-repeat, 
                radial-gradient(58.43% 103.88% at 56.74% 50%, #0085FF 0%, #003465 100%);
    min-height: 100vh;
    padding: 10px;
}
.container-fluid-register {
    background: url('../../Assets/back.png') right top/auto 500px no-repeat, radial-gradient(58.43% 103.88% at 56.74% 50%, #0085FF 0%, #003465 100%);
    min-height: 100vh;
    padding: 10px;
    padding-bottom: 50px;
}

.form-container::before {}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo img {
    height: 65px;
    width: 153px;
    margin-left: 40px;
    margin-top: 20px;
}

a {
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
}

.form-forget {
    /* height: 60%; */
    width: 50%;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    padding: 20px;
    backdrop-filter: blur(8px);
    border-radius: 40px;
    border: 3px solid #5882C1;
}
.form {
    /* height: 60%; */
    width: 45%;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* background: rgba(101, 130, 173, 0.5); */
    backdrop-filter: blur(8px);
    border-radius: 40px;
    border: 3px solid #5882C1;
}

.form-content {
    width: 85%;
    height: 40%;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;

}

.form-content .text {
    text-align: left;
    font-size: 32px;
    font-weight: 400;

}

.form-fields {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inputField {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #5882C1;
    border-radius: 10px;
}

.inputField img {
    margin: 0px 20px;
}

.inputField input {
    height: 25px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    align-self: center;
    padding: 0px 10px;
}

.inputField label {
    align-items: flex-start;
}

.forgot-password {
    font-size: 16px;
    margin-bottom: 15px;
    color: #F9A200 !important;
    font-weight: 700;
}

.forgot-password:hover {
    text-decoration: underline;
}

.form-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-button button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: #003465;
    border: 1px solid #003465;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 23.48px;
    margin-top: 25px;
    margin-bottom: 10px;
    cursor: pointer;
}

.register-text{
    font-size: 16px;
    color: #fff !important;
    text-align: center;
}


@media screen and (max-width: 768px) {
    .form-container-login {
        min-height: 100vh;
        /* align-items: center; */
    }
    .form {
        /* height: 60%; */
        width: 95%;
        margin-top: 10%;
    }
    .form-content .text {
        font-size: 30px;
    }

    .inputField {
        height: 40px;
    }

    .inputField img {
        margin: 0px 10px;
    }

    .inputField input {
        font-size: 14px;
    }

    .form-button button {
        font-size: 18px;
    }

    .register-text span {
        font-size: 14px;
    }
    
}



.loader {
    width: 28px;
    height: 28px;
    border: 5px solid;
    border-color: #fff transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 