.testimonials-bg{
  background-image: url("../../Assets/testimonial_2.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* border: 1px solid black; */
  /* display: flex; */
  /* aspect-ratio: 16/9; */
  width: 100%;
  height: 100vh;
}
.center-testimonials{
  display: flex;
  align-items: center;
}
.user-info span{
font-size: 20px ;
font-weight: 800;
line-height: 24px;
color: #1C55E0;
}
.user-info p{
font-size: 16px ;
font-weight: 500;
line-height: 19px;
color:#555555;
}
.slide-content {
  /* padding: 110px; */
  /* width: 100%; */
  background: #fff;
  color: black;
  border-radius: 13px;
  font-size: 24px;
  /* border: 1px solid darkblue; */
  display: flex;
  flex-direction: column;
  justify-content: left !important;
  box-shadow: 0px 0px 10px #00000040;
}

.swiper-slide {
  text-align: left !important;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.swiper-slide-active {
  transform: scale(0.96);
  filter: blur(0);
  z-index: 2;
  /* box-shadow:100px 100px 100px 100px; */
}

.swiper-slide:not(.swiper-slide-active) {
  transform: scale(0.9);
  filter: blur(2px);
  z-index: 1;
}

.review-message{
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  justify-content: left;
}



/* .custom-navigation { */
  /* display: flex; */
  /* } */

.slider-prev,
.slider-next {
  background-color: #fff;
  font-size: 20px;
  color: #1C55E0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* cursor: pointer; */
}

.slider-prev {
  margin-right: 10px;
}

.slider-next {
  margin-left: 10px;
}

.user-reviews{
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}
.user-img{
  width: 80px;
  height: 80px;
}
.review-head{
  display: flex;
  align-items: center;
  
}
.swiper-slide .swiper-slide-active{
  margin-right:0;
}
.customArrows{
  display: flex;
}
.slider-arrows{
  display: none;
}
@media (max-width: 768px) {
  .testimonials-bg{
    background-image: url("../../Assets/mbltests.png");
  }
  .slider-arrows{
    display: flex;
    justify-content: center
  }
  .customArrows{
    display: none;
  }
  
  .user-reviews{
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    display: flex;
    justify-content: center;
  }
  .review-head{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
  
  }
  .swiper-slide{
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    display: flex;
    justify-content: center;
    text-align: center !important;
  }
  
  .slide-content{
    position: relative;
  }
  .user-img{
    position: absolute;
    top: -32px;

  }
  .swiper-slide:not(.swiper-slide-active) {
    transform: scale(0.8);
    filter: blur(2px);
    z-index: 1;
  }
  .swiper-slide {
    transform: scale(0.85);
  }
}
@media (max-width: 450px) {
  .swiper-slide:not(.swiper-slide-active) {
    transform: scale(0.8);
    filter: blur(2px);
    z-index: 1;
  }
  .swiper-slide {
    transform: scale(0.85);
  }
  .user-img{
    position: absolute;
  }
  .slide-content{
    position: relative;
  }
}