
.product-card {
  transform: scale(var(--zoom-scale));
  transform-origin: center;
}

 .product-card {
  display: flex;
  flex-direction: row;
  background: #fff;
  border: 1px solid lightgray;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px;
  margin: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  max-width: 90%;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image {
  flex: 1 1 40%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.product-image img {
  width: 100%;
  height: 100%;
  border-left: 1px solid lightgray;
  border-radius: 10px 0 0 10px !important;
}

.product-details {
  flex: 1 1 60%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.details-header {
  display: flex;
  align-items: flex-start;
}

.product-title h4 {
  font-size: 24px;
  font-weight: 700;
  color: #dd2831;
  margin: 0;
}
.price {
  font-size: 16px;
  font-weight: 800;
  color: #dd2831;
}
.Black{
  font-size: 16px;
  font-weight: 800;
  color: #000;
}

.product-description {
  margin: 0.5rem 0;
  font-size: 1rem;
  color: #555;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.details-actions {
  display: flex;
  gap: 0.5rem;
}

.details-actions .btn-details,
.details-actions .btn-add-to-cart {
  flex: 1;
  border-radius: 8px;
  padding: 0.5rem;
  font-size: 1rem;
}

.details-actions .btn-add-to-cart {
  background-color: #f9a200;
  color: #fff;
}

.progress-height {
  height: 15px !important;
}
.compe{
  font-size: 8px !important;
  margin: 0;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .progress-height {
    height: 10px !important;
  }
  .compe{
    font-size: 6px !important;
  }
  .product-card {
    flex-direction: row;
    padding: 0px;
  }

  .product-image {
    max-width: 100%;
  }

  .product-details {
    padding: 0.5rem;
  }
  .product-title h4 {
    font-size: 16px;
  }
  .price {
    font-size: 12px;
  }
  .Black{
    font-size: 12px;
  }
  .product-description {
    -webkit-line-clamp: 1;
    font-size: 0.9rem;
  }

  .details-actions .btn-details,
  .details-actions .btn-add-to-cart {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
}

@media (max-width: 480px) {
  .product-title h4 {
    font-size: 1rem;
  }

  .product-description {
    font-size: 0.8rem;
  }

  .details-actions .btn-details,
  .details-actions .btn-add-to-cart {
    font-size: 0.7rem;
    padding: 0.2rem;
  }
}
